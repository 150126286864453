/* index.css */
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/google-sans/ProductSans-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'Product Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content-wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (min-width: 600px) {
  .content-wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media only screen and (min-width: 960px) {
  .content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}