.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white; /* Background color of the loader page */
}

.logo-wrapper {
  display: flex;
  align-items: bottom;
  gap: 20px; /* Space between the images and the dots */
}

.logo-part {
  width: 80px; /* Adjust the size of the logo parts */
  height: auto;
}

.dots {
  display: flex;
  align-items: flex-end;
  gap: 10px; /* Space between dots */
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #97befc; /* Initial color of the first dot */
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
  background-color: #639bf5; /* Slightly darker shade */
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
  background-color: #4285f4; /* Darkest dot */
}

@keyframes blink {
  0%, 20% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
